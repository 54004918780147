.ui.page.modals .ui.modal .content .info-item.delete-item {
  position: absolute;
  bottom: 1.2em;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .ui.page.modals .ui.modal .content {
    font-size: 0.9em;
  }

  .ui.page.modals .ui.modal .content .info-item.delete-item {
    width: 8em;
    position: absolute;
    bottom: -0.5em;
    right: -1em;
  }
}
