.ui.ViewerComments .ui.comments .comment .content .metadata .button-group.actions .ui.button.delete-action {
  width: 1em;
  font-size: 1.2em;
  box-shadow: none!important;
  position: absolute;
  padding-left: 4px;
  padding-top: 0;
  text-align: center;
  top: 0px;
  right: 0;

}
