/* TEMPLATE */
body .ui.tabular.menu .item {
  font-size: 1.15em;
}

body .ui.tabular.menu .item.active {
  border-top: 2px solid #002b5c;
}

body a,
body .ui.menu a.item,
body .ui.tabular.menu a.item,
body .ui.tabular.menu a.item.active,
body .ui.menu .ui.dropdown .menu>a.item,
body .ui.menu .ui.dropdown .menu>a.item.active,
body .ui.menu.secondary.pointing a.item.active,
body .ui.menu.secondary.pointing .item.dropdown>.text {
  color: #002b5c !important;
  cursor: pointer;
}

body .ui.menu.secondary.pointing .item.active {
  border-color: #002b5c;
}

body .ui.tabular.menu .item.active,
body .ui.menu.secondary.pointing .item.active {
  font-weight: 800;
}

body a:hover,
body a:active,
body a:focus,
body .ui.menu .item:hover,
body .ui.menu .item:active,
body .ui.menu .item:focus,
body .ui.tabular.menu .item:hover,
body .ui.tabular.menu .item:active,
body .ui.tabular.menu .item:focus,
body .ui.menu .ui.dropdown .menu>.item:hover,
body .ui.menu .ui.dropdown .menu>.item:active,
body .ui.menu .ui.dropdown .menu>.item:focus,
body .ui.menu.secondary.pointing .item:hover,
body .ui.menu.secondary.pointing .item:active,
body .ui.menu.secondary.pointing .item:focus,
body .ui.menu.secondary.pointing .item.active:active,
body .ui.menu.secondary.pointing .item.active:focus,
body .ui.menu.secondary.pointing .item.active:hover {
  color: #003675 !important;
}

body .ui.primary.button {
  background-color: #002b5c;
}

body .ui.primary.button:active,
body .ui.primary.button:focus,
body .ui.primary.button:hover {
  background-color: #003675;
}

body .ui.positive.button {
  background-color: #238680;
}

body .ui.positive.button:active,
body .ui.positive.button:focus,
body .ui.positive.button:hover {
  background-color: #279690;
}

body .ui.secondary.button {
  background-color: #c3c8c8;
  color: #002b5c;
}

body .ui.secondary.button:active,
body .ui.secondary.button:focus,
body .ui.secondary.button:hover {
  background-color: #aeb3b3;
  color: #002b5c;
}

/*
body .DataTable>table.ui.table {
  border-top: 4px solid #002b5c;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
*/

.ui.mini.modal {
  border-top: 2px solid #b9251e;
}

body .ui.success.message,
body .ui.success.message .header {
  color: #238680;
}

body .ui.success.message {
  box-shadow: 0 0 0 1px #238680 inset, 0 0 0 0 transparent;
}
