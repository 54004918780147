.DrawingsGrid .ui.icon.input>input {
  max-width: calc(100vw - 16em);
}

.DrawingsGrid .filters>div {
  margin-right: 1em;
  margin-bottom: 1em;
}

.DrawingsGrid .ui.multiple.search.dropdown, .DrawingsGrid .ui.multiple.search.dropdown > input.search {
  max-width: calc(100% - 32em);
  display: inline-block;
}

.DrawingsGrid .filters .buttons {
  display: inline;
}

.DrawingsGrid .ui.card>div>a {
  display: block;
  height: 256px;
  overflow: hidden;
  position: relative;
}

.DrawingsGrid .ui.card>div img {
  max-width: none;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
}

.DrawingsGrid .ui.card>div .star {
  cursor: pointer;
  z-index: 1;
}

/* Drawing Cards: mobile*/
@media only screen and (max-width: 767px)
{
  .DrawingsGrid .ui.stackable.cards>.card {
    max-width: 350px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .DrawingsGrid>.ui.vertical.segment {
    margin-top: 1em;
  }

  .DrawingsGrid .filters>div, .DrawingsGrid .filters .buttons {
    display: block;
    margin-right: 0;
  }

  .DrawingsGrid .filters .buttons button {
    float: none !important;
  }

  .DrawingsGrid .ui.multiple.search.dropdown,
  .DrawingsGrid .ui.multiple.search.dropdown > input.search,
  .DrawingsGrid .ui.icon.input>input {
    max-width: 100%;
    width: 100%;
  }
}
