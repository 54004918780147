.App .ui.top.fixed.menu {
  background-color: #fff;
}

.App .ui.top.fixed.pointing.menu {
  border-bottom-width: 1px;
}

.App .ui.top.fixed.pointing.menu .item.logo:active,
.App .ui.top.fixed.pointing.menu .item.logo.active {
  border-bottom: 0;
  margin-bottom: 0px;
}

.App .ContainedLayout main {
  padding-top: 5em;
  min-height: calc(100vh - 3.5em);
}

.App .ContainedLayout header .ui.menu .item.logo {
  padding: 0.5em 1em 0.5em 0;
}

.App .ContainedLayout header .ui.menu .item.logo img {
  width: 4em;
  margin-bottom: 0.15em;
}

.App .ContainedLayout header .ui.menu .item {
  text-transform: uppercase;
}

.App .ContainedLayout header .menu.user-menu * {
  text-transform: none;
}

.App .ContainedLayout header .mobileHeader .ui.menu .item.logo {
  padding-left: 1em;
}

.App .ContainedLayout header .mobileHeader .ui.menu .item.menu {
  padding: 0.6em;
}

.App>.top.fixed.menu .loader {
  margin-top: 0.5em;
}

/* Header NavBar: normal ------------ */
@media (min-width: 860px) {
  .normalHeader {
    display: block;
  }
  .mobileHeader {
    display: none;
  }
}
/* Header NavBar: mobile ------------ */
@media (max-width: 860px) {
  .normalHeader {
    display: none;
  }
  .mobileHeader {
    display: block;
  }
}

.dropzone {
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 100%;
  min-height: 17.35em;
  text-align: center;
  padding: 1em;
  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.dropzone:hover {
  border-color: #bbb;
}

.dropzone.empty {
  background-image: url('images/upload.svg');
}

.dropzone img {
  margin: 0 auto;
}

.App footer>.segment {
  min-height: 3.5em;
}

.modal>.actions>a {
  margin: 0px 0.5em;
}

.modal>.content>.info {
  margin-top: 2em;
}

.modal>.content>.info>.info-item {
  color: #666;
  display: inline-block;
  margin-right: 1em;
}

.ui.card>.content>.description>label {
  font-weight: bold;
}

.App .FullscreenLayout header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.App .FullscreenLayout header .logo {
  display: block;
}

.App .FullscreenLayout header .logo img {
  width: 16em;
  padding: 2em;
}

.App .FullscreenLayout header .back.button {
  padding-top: 2em;
  padding-left: 1.5em;
  display: block;
}

.App .form.form-selector {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1em;
}
