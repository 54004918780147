.DrawingViewer {
  position: relative;
  touch-action: none;
}

.DrawingViewer .DrawingCanvas {
  width: 100%;
  min-height: calc(100vh - 13.5em);
  display: block;
}

.DrawingViewer .DrawingCanvas {
  overflow: hidden;
}

.DrawingViewer .ui.sidebar {
  overflow: hidden;
  max-width: 100vw;
}

.DrawingViewer .sidebar .ui.top.segment {
  min-height: calc(100vh - 2.85em);
  max-height: calc(100vh - 2.85em);
  overflow-y: auto;
  overflow-x: hidden;
}

.DrawingViewer .sidebar .ui.top.segment {
  padding-bottom: 2em;
}

.DrawingViewer .sidebar .ui.top.segment>.ui.segment {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.DrawingViewer .sidebar .ui.top.segment h2 {
  text-align: center;
}

.DrawingViewer .sidebar a {
  cursor: pointer;
}

.DrawingViewer .sidebar .icon.close {
  position: absolute;
  left: 0.5em;
  top: 0.5em;
  z-index: 1;
  cursor: pointer;
}

.DrawingViewer .sidebar .icon.close:hover {
  color: #db2828 !important;
}

.DrawingViewer .sidebar>div {
  max-width: 100%;
  overflow: hidden;
}

.DrawingViewer .sidebar>div>.attached.menu {
  padding: 0;
  border-bottom: none;
}

.DrawingViewer .sidebar>div>.attached.menu .item {
  font-weight: bold;
  float: left;
}

.DrawingViewer .sidebar .ViewerInfo.ui.grid>.row {
  padding-bottom: 0;
}

.DrawingViewer .sidebar .ViewerInfo img {
  max-width: 100%;
  height: auto !important;
}

.DrawingViewer .sidebar .pagination.menu>.item {
  font-size: 1em;
}

.DrawingViewer .sidebar .comments {
  max-height: calc(100vh - 10em);
  overflow-y: auto;
}

.DrawingViewer .sidebar .ViewerInfo {
  max-height: calc(100vh - 8em);
  max-width: 100%;
}

.DrawingViewer .sidebar .ViewerInfo .label {
  margin-left: 0.5em;
}

.DrawingViewer .sidebar .DataTable tbody,
.DrawingViewer .sidebar .DataTable tfoot,
.DrawingViewer .sidebar .DataTable thead {
  display: block;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .DrawingViewer .sidebar .DataTable thead tr {
    padding: 0 !important;
  }
}

.DrawingViewer .sidebar .DataTable thead {
  max-height: 4em;
  overflow: hidden;
}

.DrawingViewer .sidebar .DataTable tbody {
  overflow-y: auto;
  max-height: calc(100vh - 18em);
}

.DrawingViewer .sidebar .DataTable tr {
  display: flex;
  width: 100%;
}

.DrawingViewer .sidebar .DataTable tr>td:first-child,
.DrawingViewer .sidebar .DataTable tr>th:first-child {
  width: 38%;
}

.DrawingViewer .sidebar .DataTable tr>td:last-child,
.DrawingViewer .sidebar .DataTable tr>th:last-child {
  width: 62%;
}

.DrawingViewer .sidebar .DataTable tfoot>tr>th:first-child {
  width: 100%;
}

.DrawingViewer .sidebar .ViewerTags .DataTable .pagination .paging-info {
  left: 0.75em;
  transform: none;
}

.DrawingViewer .sidebar .ViewerComments .comment {
  clear: both;
}

.DrawingViewer .sidebar .ViewerComments .comment .author:hover {
  color: #002b5c !important;
}

.DrawingViewer .sidebar .ViewerComments .comment .metadata {
  margin-left: 0;
}

.DrawingViewer .ui.sidebar .version-info {
  position: absolute;
  bottom: 0.25em;
  left: 0;
  z-index: 1;
  width: 100%;
}

.DrawingViewer .ui.sidebar .version-info>* {
  display: inline-block;
  max-height: 1em;
  color: #002b5c;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0 0.5em;
}

.DrawingViewer .ui.sidebar .version-info>img {
  float: right;
}

/* Search Dropdown inside Top Menu */
.DrawingViewer .ViewerToolbar {
  position: absolute;
  top: 1em;
  width: 100%;
}

.DrawingViewer .ViewerToolbar .search>.input {
  width: 22em;
  max-width: 100%;
}

.DrawingViewer .ViewerToolbar .search {
  max-width: calc(100vw - 17.5em);
}

.DrawingViewer .ViewerToolbar .menu>.item>.button:not(.vertical) {
  margin-left: 1em;
}

.DrawingViewer .ViewerToolbar .menu>.item.graph-controls>.button:not(.vertical) {
  margin-left: 0;
}

.DrawingViewer .ViewerToolbar .comments-container {
  font-size: 0.95em;
}

.DrawingViewer .ViewerToolbar .comments-container label {
  font-size: 0.85em;
}

.DrawingViewer .ViewerToolbar .comments-container .buttons-container {
  margin-left: 1em;
}

.DrawingViewer .ViewerToolbar>.menu {
  position: absolute;
  width: calc(100% - 7.5em);
  right: 1em;
  z-index: 1;
  transition: opacity 0.5s;
  min-height: 4.7em;
}

.DrawingViewer .ViewerToolbar>.toggle-buttons {
  position: absolute;
  right: 1.5em;
  top: 1em;
  z-index: 2;
}

.DrawingViewer .ViewerToolbar>.toggle-buttons>i {
  transition: color 256ms;
  color: #003675;
  cursor: pointer;
}

.DrawingViewer .ViewerToolbar>.toggle-buttons>i:hover {
  color: #003675;
}

.DrawingViewer .ViewerToolbar .ui.menu .results.transition {
  max-height: calc(40vh);
  overflow-y: auto;
}

.DrawingViewer .ViewerToolbar .ui.form .inline.fields {
  margin-bottom: 0;
}

.DrawingViewer .DrawingCanvas {
  min-width: 100%;
  position: relative;
}

.DrawingViewer canvas {
  display: block;
  margin: auto;
}

.DrawingViewer .canvas-info,
.DrawingViewer .canvas-loader {
  position: absolute;
  bottom: 0;
  font-size: 0.8em;
}

.DrawingViewer .canvas-info {
  right: 0.5em;
}

.DrawingViewer .canvas-loader {
  left: 0.5em;
}

.DrawingViewer .zoom-controls {
  position: absolute;
  right: 1em;
  bottom: 2em;
}

.DrawingViewer .zoom-controls img {
  filter: brightness(90%);
  cursor: pointer;
  display: block;
}

.DrawingViewer .zoom-controls img:hover {
  filter: brightness(100%);
}

.DrawingViewer .zoom-controls .icon {
  cursor: pointer;
  display: block;
  margin-bottom: 0.5em;
  background-color: #003471;
  color: #fff;
  font-size: 1.1em;
  width: 26px;
  height: 26px;
  vertical-align: bottom;
  border-radius: 26px;
  padding-top: 0.2em;
}

.DrawingViewer .zoom-controls .icon:hover {
  background-color: #003675;
}

.DrawingViewer .ui.menu .item.subsystems-selector {
  flex-grow: 1;
  max-width: 30em;
}

.DrawingViewer .ui.menu .item.subsystems-selector>.ui.dropdown {
  max-width: calc(100% - 6em);
}

.ui.popup.signal-selector:before {
  display: none;
}

.ui.popup.signal-selector {
  white-space: nowrap;
}

.ui.popup.signal-selector>button:last-child {
  margin-right: 0;
}
