.DataTable .button-group>a,
.DataTable .button-group>button {
  margin-right: 0.5em;
}

.DataTable .button-group>*:last-child {
  margin-right: 0;
}

.DataTable .button-group,
.DataTable .button-group>.button,
.DataTable .button-group>a {
  white-space: nowrap;
}

.DataTable .button.action-button,
.DataTable .button.add-button {
  float: right;
}

.DataTable .pagination {
  position: relative;
}

.DataTable .pagination .paging-info {
  opacity: 0.75;
  position: absolute;
  top: 1.5em;
  left: 50%;
  transform: translate(-50%, 0);
}

.DataTable .data-column.actions,
.DataTable .data-column.id {
  width: 1px;
}

.DataTable .ui.fluid.input {
  min-width: 6em;
}

.DataTable .datatable-controls>div {
  margin-bottom: 1em;
}

.DataTable .datatable-controls:after {
  content: '';
  display: block;
  clear: both;
}

.DataTable .datatable-controls .dropdown {
  max-width: calc(100% - 32em);
  display: inline-block;
  margin-left: 1em;
  vertical-align: top;
}

.DataTable .ui.celled.table thead tr th {
  font-size: 1.15em;
  background-color: #f7f9ff;
  color: #002b5c;
  border-left: 0;
  border-top: 2px solid #002b5c;
}

@media only screen and (max-width: 767px) {
  .DataTable .datatable-controls .dropdown {
    max-width: 100%;
    display: block;
    margin-left: 0;
  }

  .DataTable .button.action-button,
  .DataTable .button.add-button {
    float: none;
  }
}

