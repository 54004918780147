.FormGrid {
  padding-bottom: 1em;
}

.FormGrid .ui.vertical.segment.form-buttons {
  border-top: 1px solid #ddd;
  background-color: #f9fafb;
  margin: 1em -1em -1em -1em;
  padding-left: 1em;
  padding-right: 1em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.FormGrid .rich-text-editor>div:first-child {
  font-size: 0.75em;
}

.FormGrid .rich-text-editor>div:first-child select {
  height: 3em;
}

.FormGrid .rich-text-editor .DraftEditor-root {
  min-height: 7em;
}

.FormGrid .ui.vertical.segment.children {
  background-color: #f9fafb;
  margin: 1em -1em -1em -1em;
  padding-left: 1em;
  padding-right: 1em;
}
