.DataTable .button-group>a,
.DataTable .button-group>button {
  margin-right: 0.5em;
}

.DataTable .button-group>*:last-child {
  margin-right: 0;
}

.DataTable .button-group,
.DataTable .button-group>.button,
.DataTable .button-group>a {
  white-space: nowrap;
}

.DataTable .button.action-button,
.DataTable .button.add-button {
  float: right;
}

.DataTable .pagination {
  position: relative;
}

.DataTable .pagination .paging-info {
  opacity: 0.75;
  position: absolute;
  top: 1.5em;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.DataTable .data-column.actions,
.DataTable .data-column.id {
  width: 1px;
}

.DataTable .ui.fluid.input {
  min-width: 6em;
}

.DataTable .datatable-controls>div {
  margin-bottom: 1em;
}

.DataTable .datatable-controls:after {
  content: '';
  display: block;
  clear: both;
}

.DataTable .datatable-controls .dropdown {
  max-width: calc(100% - 32em);
  display: inline-block;
  margin-left: 1em;
  vertical-align: top;
}

.DataTable .ui.celled.table thead tr th {
  font-size: 1.15em;
  background-color: #f7f9ff;
  color: #002b5c;
  border-left: 0;
  border-top: 2px solid #002b5c;
}

@media only screen and (max-width: 767px) {
  .DataTable .datatable-controls .dropdown {
    max-width: 100%;
    display: block;
    margin-left: 0;
  }

  .DataTable .button.action-button,
  .DataTable .button.add-button {
    float: none;
  }
}


.DrawingsGrid .ui.icon.input>input {
  max-width: calc(100vw - 16em);
}

.DrawingsGrid .filters>div {
  margin-right: 1em;
  margin-bottom: 1em;
}

.DrawingsGrid .ui.multiple.search.dropdown, .DrawingsGrid .ui.multiple.search.dropdown > input.search {
  max-width: calc(100% - 32em);
  display: inline-block;
}

.DrawingsGrid .filters .buttons {
  display: inline;
}

.DrawingsGrid .ui.card>div>a {
  display: block;
  height: 256px;
  overflow: hidden;
  position: relative;
}

.DrawingsGrid .ui.card>div img {
  max-width: none;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-top: 0;
}

.DrawingsGrid .ui.card>div .star {
  cursor: pointer;
  z-index: 1;
}

/* Drawing Cards: mobile*/
@media only screen and (max-width: 767px)
{
  .DrawingsGrid .ui.stackable.cards>.card {
    max-width: 350px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .DrawingsGrid>.ui.vertical.segment {
    margin-top: 1em;
  }

  .DrawingsGrid .filters>div, .DrawingsGrid .filters .buttons {
    display: block;
    margin-right: 0;
  }

  .DrawingsGrid .filters .buttons button {
    float: none !important;
  }

  .DrawingsGrid .ui.multiple.search.dropdown,
  .DrawingsGrid .ui.multiple.search.dropdown > input.search,
  .DrawingsGrid .ui.icon.input>input {
    max-width: 100%;
    width: 100%;
  }
}

.FormGrid {
  padding-bottom: 1em;
}

.FormGrid .ui.vertical.segment.form-buttons {
  border-top: 1px solid #ddd;
  background-color: #f9fafb;
  margin: 1em -1em -1em -1em;
  padding-left: 1em;
  padding-right: 1em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.FormGrid .rich-text-editor>div:first-child {
  font-size: 0.75em;
}

.FormGrid .rich-text-editor>div:first-child select {
  height: 3em;
}

.FormGrid .rich-text-editor .DraftEditor-root {
  min-height: 7em;
}

.FormGrid .ui.vertical.segment.children {
  background-color: #f9fafb;
  margin: 1em -1em -1em -1em;
  padding-left: 1em;
  padding-right: 1em;
}

.CommentEditor>.ui.grid>.row {
  padding-top: 0;
}

.CommentEditor .radio.checkbox {
  margin-right: 1em;
}

.ui.ViewerComments .ui.comments .comment .content .metadata .button-group.actions .ui.button.delete-action {
  width: 1em;
  font-size: 1.2em;
  box-shadow: none!important;
  position: absolute;
  padding-left: 4px;
  padding-top: 0;
  text-align: center;
  top: 0px;
  right: 0;

}

.ui.page.modals .ui.modal .content .info-item.delete-item {
  position: absolute;
  bottom: 1.2em;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .ui.page.modals .ui.modal .content {
    font-size: 0.9em;
  }

  .ui.page.modals .ui.modal .content .info-item.delete-item {
    width: 8em;
    position: absolute;
    bottom: -0.5em;
    right: -1em;
  }
}

.DrawingViewer {
  position: relative;
  touch-action: none;
}

.DrawingViewer .DrawingCanvas {
  width: 100%;
  min-height: calc(100vh - 13.5em);
  display: block;
}

.DrawingViewer .DrawingCanvas {
  overflow: hidden;
}

.DrawingViewer .ui.sidebar {
  overflow: hidden;
  max-width: 100vw;
}

.DrawingViewer .sidebar .ui.top.segment {
  min-height: calc(100vh - 2.85em);
  max-height: calc(100vh - 2.85em);
  overflow-y: auto;
  overflow-x: hidden;
}

.DrawingViewer .sidebar .ui.top.segment {
  padding-bottom: 2em;
}

.DrawingViewer .sidebar .ui.top.segment>.ui.segment {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.DrawingViewer .sidebar .ui.top.segment h2 {
  text-align: center;
}

.DrawingViewer .sidebar a {
  cursor: pointer;
}

.DrawingViewer .sidebar .icon.close {
  position: absolute;
  left: 0.5em;
  top: 0.5em;
  z-index: 1;
  cursor: pointer;
}

.DrawingViewer .sidebar .icon.close:hover {
  color: #db2828 !important;
}

.DrawingViewer .sidebar>div {
  max-width: 100%;
  overflow: hidden;
}

.DrawingViewer .sidebar>div>.attached.menu {
  padding: 0;
  border-bottom: none;
}

.DrawingViewer .sidebar>div>.attached.menu .item {
  font-weight: bold;
  float: left;
}

.DrawingViewer .sidebar .ViewerInfo.ui.grid>.row {
  padding-bottom: 0;
}

.DrawingViewer .sidebar .ViewerInfo img {
  max-width: 100%;
  height: auto !important;
}

.DrawingViewer .sidebar .pagination.menu>.item {
  font-size: 1em;
}

.DrawingViewer .sidebar .comments {
  max-height: calc(100vh - 10em);
  overflow-y: auto;
}

.DrawingViewer .sidebar .ViewerInfo {
  max-height: calc(100vh - 8em);
  max-width: 100%;
}

.DrawingViewer .sidebar .ViewerInfo .label {
  margin-left: 0.5em;
}

.DrawingViewer .sidebar .DataTable tbody,
.DrawingViewer .sidebar .DataTable tfoot,
.DrawingViewer .sidebar .DataTable thead {
  display: block;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .DrawingViewer .sidebar .DataTable thead tr {
    padding: 0 !important;
  }
}

.DrawingViewer .sidebar .DataTable thead {
  max-height: 4em;
  overflow: hidden;
}

.DrawingViewer .sidebar .DataTable tbody {
  overflow-y: auto;
  max-height: calc(100vh - 18em);
}

.DrawingViewer .sidebar .DataTable tr {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.DrawingViewer .sidebar .DataTable tr>td:first-child,
.DrawingViewer .sidebar .DataTable tr>th:first-child {
  width: 38%;
}

.DrawingViewer .sidebar .DataTable tr>td:last-child,
.DrawingViewer .sidebar .DataTable tr>th:last-child {
  width: 62%;
}

.DrawingViewer .sidebar .DataTable tfoot>tr>th:first-child {
  width: 100%;
}

.DrawingViewer .sidebar .ViewerTags .DataTable .pagination .paging-info {
  left: 0.75em;
  -webkit-transform: none;
          transform: none;
}

.DrawingViewer .sidebar .ViewerComments .comment {
  clear: both;
}

.DrawingViewer .sidebar .ViewerComments .comment .author:hover {
  color: #002b5c !important;
}

.DrawingViewer .sidebar .ViewerComments .comment .metadata {
  margin-left: 0;
}

.DrawingViewer .ui.sidebar .version-info {
  position: absolute;
  bottom: 0.25em;
  left: 0;
  z-index: 1;
  width: 100%;
}

.DrawingViewer .ui.sidebar .version-info>* {
  display: inline-block;
  max-height: 1em;
  color: #002b5c;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0 0.5em;
}

.DrawingViewer .ui.sidebar .version-info>img {
  float: right;
}

/* Search Dropdown inside Top Menu */
.DrawingViewer .ViewerToolbar {
  position: absolute;
  top: 1em;
  width: 100%;
}

.DrawingViewer .ViewerToolbar .search>.input {
  width: 22em;
  max-width: 100%;
}

.DrawingViewer .ViewerToolbar .search {
  max-width: calc(100vw - 17.5em);
}

.DrawingViewer .ViewerToolbar .menu>.item>.button:not(.vertical) {
  margin-left: 1em;
}

.DrawingViewer .ViewerToolbar .menu>.item.graph-controls>.button:not(.vertical) {
  margin-left: 0;
}

.DrawingViewer .ViewerToolbar .comments-container {
  font-size: 0.95em;
}

.DrawingViewer .ViewerToolbar .comments-container label {
  font-size: 0.85em;
}

.DrawingViewer .ViewerToolbar .comments-container .buttons-container {
  margin-left: 1em;
}

.DrawingViewer .ViewerToolbar>.menu {
  position: absolute;
  width: calc(100% - 7.5em);
  right: 1em;
  z-index: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  min-height: 4.7em;
}

.DrawingViewer .ViewerToolbar>.toggle-buttons {
  position: absolute;
  right: 1.5em;
  top: 1em;
  z-index: 2;
}

.DrawingViewer .ViewerToolbar>.toggle-buttons>i {
  -webkit-transition: color 256ms;
  transition: color 256ms;
  color: #003675;
  cursor: pointer;
}

.DrawingViewer .ViewerToolbar>.toggle-buttons>i:hover {
  color: #003675;
}

.DrawingViewer .ViewerToolbar .ui.menu .results.transition {
  max-height: calc(40vh);
  overflow-y: auto;
}

.DrawingViewer .ViewerToolbar .ui.form .inline.fields {
  margin-bottom: 0;
}

.DrawingViewer .DrawingCanvas {
  min-width: 100%;
  position: relative;
}

.DrawingViewer canvas {
  display: block;
  margin: auto;
}

.DrawingViewer .canvas-info,
.DrawingViewer .canvas-loader {
  position: absolute;
  bottom: 0;
  font-size: 0.8em;
}

.DrawingViewer .canvas-info {
  right: 0.5em;
}

.DrawingViewer .canvas-loader {
  left: 0.5em;
}

.DrawingViewer .zoom-controls {
  position: absolute;
  right: 1em;
  bottom: 2em;
}

.DrawingViewer .zoom-controls img {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
  cursor: pointer;
  display: block;
}

.DrawingViewer .zoom-controls img:hover {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.DrawingViewer .zoom-controls .icon {
  cursor: pointer;
  display: block;
  margin-bottom: 0.5em;
  background-color: #003471;
  color: #fff;
  font-size: 1.1em;
  width: 26px;
  height: 26px;
  vertical-align: bottom;
  border-radius: 26px;
  padding-top: 0.2em;
}

.DrawingViewer .zoom-controls .icon:hover {
  background-color: #003675;
}

.DrawingViewer .ui.menu .item.subsystems-selector {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 30em;
}

.DrawingViewer .ui.menu .item.subsystems-selector>.ui.dropdown {
  max-width: calc(100% - 6em);
}

.ui.popup.signal-selector:before {
  display: none;
}

.ui.popup.signal-selector {
  white-space: nowrap;
}

.ui.popup.signal-selector>button:last-child {
  margin-right: 0;
}

.App .ui.top.fixed.menu {
  background-color: #fff;
}

.App .ui.top.fixed.pointing.menu {
  border-bottom-width: 1px;
}

.App .ui.top.fixed.pointing.menu .item.logo:active,
.App .ui.top.fixed.pointing.menu .item.logo.active {
  border-bottom: 0;
  margin-bottom: 0px;
}

.App .ContainedLayout main {
  padding-top: 5em;
  min-height: calc(100vh - 3.5em);
}

.App .ContainedLayout header .ui.menu .item.logo {
  padding: 0.5em 1em 0.5em 0;
}

.App .ContainedLayout header .ui.menu .item.logo img {
  width: 4em;
  margin-bottom: 0.15em;
}

.App .ContainedLayout header .ui.menu .item {
  text-transform: uppercase;
}

.App .ContainedLayout header .menu.user-menu * {
  text-transform: none;
}

.App .ContainedLayout header .mobileHeader .ui.menu .item.logo {
  padding-left: 1em;
}

.App .ContainedLayout header .mobileHeader .ui.menu .item.menu {
  padding: 0.6em;
}

.App>.top.fixed.menu .loader {
  margin-top: 0.5em;
}

/* Header NavBar: normal ------------ */
@media (min-width: 860px) {
  .normalHeader {
    display: block;
  }
  .mobileHeader {
    display: none;
  }
}
/* Header NavBar: mobile ------------ */
@media (max-width: 860px) {
  .normalHeader {
    display: none;
  }
  .mobileHeader {
    display: block;
  }
}

.dropzone {
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 100%;
  min-height: 17.35em;
  text-align: center;
  padding: 1em;
  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.dropzone:hover {
  border-color: #bbb;
}

.dropzone.empty {
  background-image: url(/static/media/upload.caa81b54.svg);
}

.dropzone img {
  margin: 0 auto;
}

.App footer>.segment {
  min-height: 3.5em;
}

.modal>.actions>a {
  margin: 0px 0.5em;
}

.modal>.content>.info {
  margin-top: 2em;
}

.modal>.content>.info>.info-item {
  color: #666;
  display: inline-block;
  margin-right: 1em;
}

.ui.card>.content>.description>label {
  font-weight: bold;
}

.App .FullscreenLayout header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.App .FullscreenLayout header .logo {
  display: block;
}

.App .FullscreenLayout header .logo img {
  width: 16em;
  padding: 2em;
}

.App .FullscreenLayout header .back.button {
  padding-top: 2em;
  padding-left: 1.5em;
  display: block;
}

.App .form.form-selector {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1em;
}

/* TEMPLATE */
body .ui.tabular.menu .item {
  font-size: 1.15em;
}

body .ui.tabular.menu .item.active {
  border-top: 2px solid #002b5c;
}

body a,
body .ui.menu a.item,
body .ui.tabular.menu a.item,
body .ui.tabular.menu a.item.active,
body .ui.menu .ui.dropdown .menu>a.item,
body .ui.menu .ui.dropdown .menu>a.item.active,
body .ui.menu.secondary.pointing a.item.active,
body .ui.menu.secondary.pointing .item.dropdown>.text {
  color: #002b5c !important;
  cursor: pointer;
}

body .ui.menu.secondary.pointing .item.active {
  border-color: #002b5c;
}

body .ui.tabular.menu .item.active,
body .ui.menu.secondary.pointing .item.active {
  font-weight: 800;
}

body a:hover,
body a:active,
body a:focus,
body .ui.menu .item:hover,
body .ui.menu .item:active,
body .ui.menu .item:focus,
body .ui.tabular.menu .item:hover,
body .ui.tabular.menu .item:active,
body .ui.tabular.menu .item:focus,
body .ui.menu .ui.dropdown .menu>.item:hover,
body .ui.menu .ui.dropdown .menu>.item:active,
body .ui.menu .ui.dropdown .menu>.item:focus,
body .ui.menu.secondary.pointing .item:hover,
body .ui.menu.secondary.pointing .item:active,
body .ui.menu.secondary.pointing .item:focus,
body .ui.menu.secondary.pointing .item.active:active,
body .ui.menu.secondary.pointing .item.active:focus,
body .ui.menu.secondary.pointing .item.active:hover {
  color: #003675 !important;
}

body .ui.primary.button {
  background-color: #002b5c;
}

body .ui.primary.button:active,
body .ui.primary.button:focus,
body .ui.primary.button:hover {
  background-color: #003675;
}

body .ui.positive.button {
  background-color: #238680;
}

body .ui.positive.button:active,
body .ui.positive.button:focus,
body .ui.positive.button:hover {
  background-color: #279690;
}

body .ui.secondary.button {
  background-color: #c3c8c8;
  color: #002b5c;
}

body .ui.secondary.button:active,
body .ui.secondary.button:focus,
body .ui.secondary.button:hover {
  background-color: #aeb3b3;
  color: #002b5c;
}

/*
body .DataTable>table.ui.table {
  border-top: 4px solid #002b5c;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
*/

.ui.mini.modal {
  border-top: 2px solid #b9251e;
}

body .ui.success.message,
body .ui.success.message .header {
  color: #238680;
}

body .ui.success.message {
  box-shadow: 0 0 0 1px #238680 inset, 0 0 0 0 transparent;
}

